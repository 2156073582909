<template>
  <b-container class="import-account-statement-spreadsheet-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>

        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">
            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step" @on-change="onStepChange">
              <tab-content :title="$t(translationPath + 'tab_title_step1')" :before-change="()=>validateStep('step1')">
                <import-account-statement-spreadsheet-advanced-wizard-step01 ref="step1" @offset-accounts-loaded="onOffsetAccountsLoaded" @validate-success="mergePartialModels"></import-account-statement-spreadsheet-advanced-wizard-step01>
              </tab-content>
              <tab-content :title="$t(translationPath + 'tab_title_step2')" :before-change="()=>validateStep('step2')">
                <import-account-statement-spreadsheet-advanced-wizard-step02 ref="step2" :columns="finalModel.columnHeaders" @validate-success="mergePartialModels" :fileName="finalModel.fileName" :skip="Boolean(finalModel.textData)"></import-account-statement-spreadsheet-advanced-wizard-step02>
              </tab-content>
              <tab-content :title="$t(translationPath + 'tab_title_step3')" :before-change="()=>validateStep('step3')">
                <import-account-statement-spreadsheet-advanced-wizard-step03
                  ref="step3"
                  :finalModel="finalModel"
                  @validate-success="mergePartialModels"
                />
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex != 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex != 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button tabindex="5" v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle">{{$t('common.finalize_the_import')}}</wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>
            <success-modal
              :title="$t(translationPath+'success_modal_title')"
              :message="$t(translationPath+'success_modal_message')"
              :button="$t(translationPath+'success_modal_button_text')"
              :redirect="'/redirect?to=importSpreadsheetFileAdvanced'"
              :orLinkText="$t(translationPath+'success_modal_or_link_text')"
              :orLink="'/redirect?to=viewTransactions'"
              @hide="onHideSuccessModal"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import SuccessModal from '@/components/modals/SuccessModal'
import ImportAccountStatementSpreadsheetAdvancedWizardStep01 from './ImportAccountStatementSpreadsheetAdvancedWizardStep01'
import ImportAccountStatementSpreadsheetAdvancedWizardStep02 from './ImportAccountStatementSpreadsheetAdvancedWizardStep02'
import ImportAccountStatementSpreadsheetAdvancedWizardStep03 from './ImportAccountStatementSpreadsheetAdvancedWizardStep03'
import TitleMixins from '@/mixins/title'

export default {
  name: 'ImportAccountStatementSpreadsheetAdvanced',
  mixins: [TitleMixins],
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    SuccessModal,
    ImportAccountStatementSpreadsheetAdvancedWizardStep01,
    ImportAccountStatementSpreadsheetAdvancedWizardStep02,
    ImportAccountStatementSpreadsheetAdvancedWizardStep03
    // ImportAccountStatementWizardStep03,
    // ImportAccountStatementWizardStep04
  },
  data () {
    return {
      finalModel: {
        fileName: null,
        columnHeaders: [],
        matchedColumns: {},
        transactions: [],
        defaultOffsetAccount: null,
        textData: null
      },
      nextDisabled: {
        0: false,
        1: false,
        2: false,
        3: false
      },
      offsetAccountOptions: [],
      translationPath: 'transactions.import.import-spreadsheet-advanced-wizard.common.'
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('transactions.import.import-spreadsheet-wizard.common.title')
    }
  },
  methods: {
    async validateStep (name) {
      const validateStatus = await this.$refs[name].validate()
      if (name === 'step2' && validateStatus) {
        // this.$refs.step3.scrollToTop()
      }
      return validateStatus
    },
    disableSave (value) {
      this.nextDisabled['3'] = value
    },
    disableNext (value) {
      this.nextDisabled['2'] = value
    },
    mergePartialModels (model) {
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    onOffsetAccountsLoaded (accounts) {
      this.offsetAccountOptions = accounts
    },
    onStepChange (prevIndex, nextIndex) {
      if (nextIndex === 1 && prevIndex === 0 && this.finalModel.textData) {
        process.nextTick(() => {
          this.$refs.step.nextTab()
        })
      } else if (nextIndex === 1 && prevIndex === 2 && this.finalModel.textData) {
        process.nextTick(() => {
          this.$refs.step.prevTab()
        })
      } else if (nextIndex === 1 && prevIndex > 1) {
        this.finalModel.matchedColumns = {}
      }
    },
    onComplete () {
      this.$refs.step3.validate()
        .then(status => {
          if (status) {
            this.$bvModal.show('successModal')
          }
        })
    },
    onHideSuccessModal () {
      this.$router.push('/redirect?to=importSpreadsheetFile')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';

.import-account-statement-spreadsheet-wizard {
  .detail-form {
    border-left: 1px solid #d7d7d7;
  }
  textarea {
    font-size: 75%;
    font-family: "Lucida Console";
  }
}
</style>
