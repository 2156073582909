<template>
  <b-container class="import-wizard-step import-wizard-step02">
    <b-row no-gutters>
      <b-col cols="4" class="detail-text text-left py-5 px-5" align-self="start">
        <h2 class="text-dark">{{ $t(translationPath + 'intro_title') }}</h2>
        <i18n :path="translationPath + 'intro_p1'" tag="p">
          <b-link slot="link" href="javascript:void(0);" :data-beacon-article-sidebar="helpLink">{{ $t('common.read_in_manual') }}</b-link>
        </i18n>
      </b-col>
      <b-col cols="8" class="detail-form text-left py-5 px-5" align-self="center">
        <b-alert variant="danger" :show="showAlertDanger">
          <b>Reason why import is not working</b>
          <p class="mb-1">You need to follow these instructions</p>
          <template v-if="incomeAccountErrors.length > 0">
            <b class="mb-1">Please create asset/liability account with the name</b>
            <ul class="error-report income">
              <div v-for="(transactionError, transactionErrorIndex) in incomeAccountErrors" :key="`error_income_${transactionErrorIndex}`">
                {{transactionError}}
              </div>
            </ul>
          </template>
          <template v-if="assetsAccountErrors.length > 0">
            <b class="mb-1">Please create income/expense account with the name</b>
            <ul class="error-report income">
              <div v-for="(transactionError, transactionErrorIndex) in assetsAccountErrors" :key="`error_asset_${transactionErrorIndex}`">
                {{transactionError}}
              </div>
            </ul>
          </template>
        </b-alert>
        <h3>{{ $t(translationPath + 'required_columns') }}</h3>
        <p>{{ $t(translationPath + 'required_columns_description') }}</p>
        <!-- Date -->
        <b-form-group
          label-for="date"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidDateColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_date') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_date_help_text')}}</template>
          <b-form-select id="date"
            v-model="matched.date"
            :options="fileColumnOptions"
            :state="$v.matched.date.$dirty ? !$v.matched.date.$error : null"
            @input="$v.matched.date.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Title -->
        <b-form-group
          label-for="title"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidTitleColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_title') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_title_help_text')}}</template>
          <b-form-select id="title"
            v-model="matched.title"
            :options="fileColumnOptions"
            :state="$v.matched.title.$dirty ? !$v.matched.title.$error : null"
            @input="$v.matched.title.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Amount -->
        <b-form-group
          label-for="amount"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidAmountColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_amount') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_amount_help_text')}}</template>
          <b-form-select id="amount"
            v-model="matched.amount"
            :options="fileColumnOptions"
            :state="$v.matched.amount.$dirty ? !$v.matched.amount.$error : null"
            @input="$v.matched.amount.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Credit -->
        <b-form-group
          label-for="credit-account"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidCreditAccountColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_credit_account') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_credit_account_help_text')}}</template>
          <b-form-select id="amount"
            v-model="matched.creditAccount"
            :options="fileColumnOptions"
            :state="$v.matched.creditAccount.$dirty ? !$v.matched.creditAccount.$error : null"
            @input="$v.matched.creditAccount.$touch()"
          ></b-form-select>
        </b-form-group>

        <!-- Debit -->
        <b-form-group
          label-for="debit-account"
          label-cols-lg="3"
          label-align-lg="left"
          required
          label-class="pr-4 text-black"
          :invalid-feedback="invalidDebitAccountColumnFeedback"
        >
          <template slot="label">{{ $t(translationPath + 'label_debit_account') }}</template>
          <template slot="description">{{ $t(translationPath + 'label_debit_account_help_text')}}</template>
          <b-form-select id="amount"
            v-model="matched.debitAccount"
            :options="fileColumnOptions"
            :state="$v.matched.debitAccount.$dirty ? !$v.matched.debitAccount.$error : null"
            @input="$v.matched.debitAccount.$touch()"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import ImportAccountStatementSpreadsheetAdvancedMixin from './ImportAccountStatementSpreadsheetAdvancedMixin'

export default {
  name: 'ImportAccountStatementSpreadsheetAdvancedWizardStep02',
  mixins: [ImportAccountStatementSpreadsheetAdvancedMixin],
  props: ['columns', 'fileName', 'skip'],
  validations: {
    matched: {
      date: { required },
      title: { required },
      amount: { required },
      creditAccount: { required },
      debitAccount: { required }
    },
    form: ['matched.date', 'matched.title', 'matched.amount']
  },
  data () {
    return {
      translationPath: 'transactions.import.import-spreadsheet-wizard.step2.',
      matched: {
        date: null,
        title: null,
        amount: null,
        creditAccount: null,
        debitAccount: null
      },
      form_account_data: {}
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    showAlertDanger () {
      const successRate = this.form_account_data && Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'success_rate') ? this.form_account_data.parse_result.success_rate : -1
      return successRate === 0
    },
    incomeAccountErrors () {
      return this.form_account_data && Object.prototype.hasOwnProperty.call(this.form_account_data, 'transactions_error') && this.form_account_data.transactions_error ? this.form_account_data.transactions_error.filter((el) => el.categoryId === 0).map((el) => el.creditAccount).filter((el, index, array) => array.indexOf(el) === index) : []
    },
    assetsAccountErrors () {
      return this.form_account_data && Object.prototype.hasOwnProperty.call(this.form_account_data, 'transactions_error') && this.form_account_data.transactions_error ? this.form_account_data.transactions_error.filter((el) => el.offsetAccountId === 0).map((el) => el.debitAccount).filter((el, index, array) => array.indexOf(el) === index) : []
    },
    fileColumnOptions () {
      const columns = [{ text: '', value: '' }]
      let columnNumber = 0
      for (var prop in this.columns) {
        columnNumber++
        const item = {
          text: this.columns[prop],
          value: columnNumber
        }
        columns.push(item)
      }
      return columns
    },
    invalidDateColumnFeedback () {
      if (this.$v.matched.date.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidTitleColumnFeedback () {
      if (this.$v.matched.title.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidAmountColumnFeedback () {
      if (this.$v.matched.amount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidCreditAccountColumnFeedback () {
      if (this.$v.matched.creditAccount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    invalidDebitAccountColumnFeedback () {
      if (this.$v.matched.debitAccount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    },
    /** Todo: implement */
    helpLink () {
      return ''
    }
  },
  methods: {
    async validate () {
      if (this.skip) {
        return true
      }

      this.$v.form.$touch()
      let isValid = !this.$v.form.$invalid
      if (isValid) {
        this.$bvToast.toast(this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_description'), {
          title: this.$t('transactions.import.import_wizard_v2.step2.toast.trying_to_parse_title'),
          variant: 'info',
          solid: true
        })
        isValid = await this.prepareParseResponse(await this.parseFile())
      }
      return isValid
    },
    parseFile () {
      if (!this.matched.amount || !this.matched.date || !this.matched.title || !this.matched.creditAccount || !this.matched.debitAccount) {
        return false
      }
      const postData = {
        filename: this.fileName,
        matched_columns: this.matched
      }

      return axios.post(process.env.VUE_APP_ROOT_API + '/transactions/import-spreadsheet-advanced/parse', postData)
        .then(response => response.data.data)
        .then(response => {
          return response
        })
        .catch((error) => {
          console.error(error)
          return null
        })
    }
  },
  watch: {
    columns: {
      deep: true,
      handler () {
        for (var index in this.columns) {
          const colnum = parseInt(index) + 1
          switch (this.columns[index].toLowerCase()) {
            case 'date':
            case 'datum':
              this.matched.date = colnum
              break
            case 'title':
            case 'titel':
            case 'beskrivning':
              this.matched.title = colnum
              break
            case 'belopp':
            case 'amount':
              this.matched.amount = colnum
              break
            case 'credit':
            case 'credit-account':
            case 'credit account':
            case 'creditaccount':
            case 'credit_account':
            case 'kreditkonto':
            case 'kredit':
              this.matched.creditAccount = colnum
              break
            case 'debit-account':
            case 'debit account':
            case 'debitaccount':
            case 'debit_account':
            case 'debit':
            case 'debet':
            case 'debetkonto':
              this.matched.debitAccount = colnum
              break
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.import-wizard-step02 {
  .detail-form {
    label {
      line-height: 32px;
    }
  }
  .error-report {
    padding: 0px 0px 0px 10px;
  }
  .dotted {
    padding-top: 25px;
    border-top: 1px dashed gray;
  }
}
</style>
