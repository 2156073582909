import axios from 'axios'
import moment from 'moment'

export default {
  methods: {
    async prepareParseResponse (apiResponseData) {
      const responseData = Object.prototype.hasOwnProperty.call(apiResponseData, 'result_object') && apiResponseData.result_object ? apiResponseData.result_object : {}
      const responseObject = Object.prototype.hasOwnProperty.call(responseData, 'data') && responseData.data ? responseData.data : {}
      const parsedTransaction = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_ok') ? responseObject.with_parse_ok : []
      const parsedError = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_error') ? responseObject.with_parse_error : []
      const parsedRawData = Object.keys(responseData).filter(item => item !== 'data').map(item => {
        const Obj = {}
        Obj[item] = responseData[item]
        return Obj
      }).reduce((acc, item) => Object.assign(acc, item))
      // console.log('parsedTransaction :::: ', parsedTransaction)
      // console.log('parsedError :::: ', parsedError)
      // console.log('parsedRawData :::: ', parsedRawData)
      const mutatedTransactions = parsedTransaction

      this.form_account_data = Object.assign({}, this.form_account_data, {
        parsed_account_statement: responseObject,
        parsed_transactions: parsedTransaction,
        transactions: mutatedTransactions,
        transactions_error: parsedError,
        parse_result: parsedRawData
      })

      if (this.showAlertDanger) {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.parse_failed_description'), {
          title: this.$t(this.translationPath + 'toast.parse_failed_title'),
          variant: 'danger',
          solid: true
        })
        this.scrollToError()
        this.textDataParseError = true
        return false
      }

      const transactionRawData = Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'raw_data_string') && this.form_account_data.parse_result.raw_data_string ? this.form_account_data.parse_result.raw_data_string : ''
      // save transaction Object
      const importTitle = this.$t('transactions.import.import_wizard_v2.step4.import_title_default_suggestion_v2', {
        date: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        parser: 'advanced-excel-import-parser'
      })
      const createImportObject = {
        account_id: null,
        title: importTitle,
        raw_account_statement: transactionRawData,
        used_parser: 'advanced-excel-import-parser',
        success_rate: this.form_account_data.parse_result.success_rate
      }
      const bankStatementResponse = await axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/create`, createImportObject)
      const bankResponse = bankStatementResponse.status === 201 ? bankStatementResponse.data.data : {}
      if (Object.keys(bankResponse).length === 0) {
        this.textDataParseError = true
        return false
      }
      const importId = Object.prototype.hasOwnProperty.call(bankResponse, 'id') ? bankResponse.id : null

      if (this.form_account_data.parse_result.entries_passed > 0) {
        // toast success message
        const toastSuccessMessage = this.$t(this.translationPath + 'alert.successfully_identified_transactions')
        // toast error message
        // const toastErroreMessage = this.$t('transactions.import.import_wizard_v2.step3.all_transcations_could_not_be_parsed_title', { parserName: this.$t('common.' + this.form_account_data.parse_result.parser_nice_name) })
        const toastErroreMessage = this.$t('transactions.import.import_wizard_v2.step3.all_transcations_could_not_be_parsed_title', { parserName: 'Excel' })
        // toast heading
        const toastHeading = this.form_account_data.parse_result.entries_failed === 0 ? toastSuccessMessage : toastErroreMessage
        // toast message
        const toastMessage = this.$t(this.translationPath + 'alert.successfully_identified_transactions_description', { number: this.form_account_data.parse_result.entries_passed })
        // toast variant
        const toastvariant = this.form_account_data.parse_result.entries_failed === 0 ? 'success' : 'warning'
        // toast
        this.$bvToast.toast(toastMessage, {
          title: toastHeading,
          variant: toastvariant,
          solid: true,
          id: 'parseTransactions',
          autoHideDelay: 5000
        })
      }
      const emitObject = Object.assign({}, { import_id: importId }, this.form_account_data)
      emitObject.textData = this.textData
      this.$emit('validate-success', emitObject)
      this.$emit('reset-edit-state')
      return true
    }
  }
}
